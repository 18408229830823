import React from "react"
import styled from "styled-components"

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 1.45rem;
  max-width: ${props => props.fullwidth ? "100%" : "960px"};

`

export default Container;